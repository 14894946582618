console.log('_ajax.js');

$ajax = $('body>div.ajaxresult');



var akajax = function(){ console.log('ajax() -->');

	$(document).on('click', 'div.akajax', function(event) { console.log('a.akajax <- click '+event.target);

		var $appendto = $('div#container section#aklightbox');
		var $link = $(this).find('a').attr('href');

		event.preventDefault();

		if ( $appendto.find('section.akajax-container').length ){
			$ajaxcontainer = $appendto.find('section.akajax-container');
			akajaxcallback($ajaxcontainer, false);
			return;
		}

		//$appendto.append('<section class="akajax-container"></div>');
		//var $ajaxcontainer = $appendto.find('section.akajax-container');

		console.log('ajax() --> load '+$link);

		// $.ajax({
		//   url: $link,
		//   dataType: "html",
		//   success: function(data) { console.log('ajax() --> loaded '+$link);
		//     ajaxresult = $(data).filter('section.ajaxresult');
		//     console.log(ajaxresult);
		//     $appendto.html(ajaxresult);
		//     $appendto.find('section.ajaxresult').attr('id','');

		// 		akajaxcallback($appendto, true);
		//   }
		// });

		$appendto.load($link+' section.ajaxresult', function(){
			akajaxcallback($appendto, true);
		});


	});
}; akajax();


var akajaxcallback = function($appendto, isnew){ console.log('akajaxcallback() -->');
	$appendto.find('section.ajaxresult').attr('id','');
	$appendto.show();
	initswiper();
	$('div.akjplayerV').akjplayerV();

	add_video_button_to_images();

};



var ajaxdies = function(){ console.log('ajaxdies() -->');

	$ajax.remove();

}; ajaxdies();
