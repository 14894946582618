console.log('form.js ->');

var webform = function(){ console.log('webform() ->');
	
	$('form.webform-client-form, form.mailchimp-signup-subscribe-form').each(function(index, el) {

		$('.form-item').each(function(index, el) {

			$(this).find('label').find('span.form-required').remove();

			var placeholder = $(this).find('label').text();
			$(this).find('input[type=text], textarea').attr('placeholder', placeholder);

		});

	});

}; webform();

