console.log('featured.js ->');

var featured_events = function(){

    $(document).on({
        mouseenter: function(event){ console.log('article.node <- hover');
            $(this).closest('article.node').addClass('hover');
        },
        mouseleave: function(event){
            $(this).closest('article.node').removeClass('hover');
        }
    },'div.view-featured article.node div.element');

}; featured_events();