console.log('_adminmenu.js v2.7 ...');

//ADMINMENU KEYBOARD LOGIN
function adminmenu(){ console.log('adminmenu() ->');

  $('#aklogin').akaddplaceholder(true, true);

  console.log('Adminmenu/Login hidden. Press CTRL+^ or CTRL+a to toggle.');

  $(document).on('keydown', function(e) {
    console.log(e.which);

    if ( e.ctrlKey && (e.which == 27 || e.which == 65 || e.which == 192 || e.which == 160) ){

      if ( $('input').is(':focus') ){
        console.log('Admin prevented');
        return false;
      }
      console.log('Hello Admin');

      if( $('body').hasClass('VIP') ){

        if (e.shiftKey) window.open(Drupal.settings.basePath+'admin');
        else window.location.href = "#overlay=admin";
      }
      else if ( $('#aklogin').length ){
        $('#aklogin').slideToggle('fast');
      }
      else {
          window.location.href = Drupal.settings.basePath+'user/login'
      }
    }

    // if ( e.ctrlKey && (e.which == 67 && $('body').hasClass('VIP') ) ){

    //   if ( $('input').is(':focus') ){
    //     console.log('Admin prevented')
    //     return false;
    //   }
    //   console.log('Hello Admin');

    //   window.location.href = "#overlay=admin/config/development/performance"
    // }

    // if ( e.ctrlKey && (e.which == 76 && $('body').hasClass('VIP') ) ){

    //   if ( $('input').is(':focus') ){
    //     console.log('Admin prevented')
    //     return false;
    //   }
    //   console.log('Hello Admin');

    //   window.location.href = Drupal.settings.basePath+'user/logout'
    // }

    if ( e.ctrlKey && (e.which == 73 && $('body').hasClass('VIP') ) ){

      if ( $('input').is(':focus') ){
        console.log('Admin prevented')
        return false;
      }
      console.log('Hello Admin');

      window.location.href = "#overlay=admin/content"
    }

    if ( e.ctrlKey && (e.which == 69 && $('body').hasClass('VIP') ) ){

      if (e.shiftKey) $('.akedit').remove();
      else articleedit();
    }

  });
};
//ADMINMENU END



//ARTICLE EDIT-ICON
function articleedit(){ console.log('articleedit() ->');
  if ( !$('body').hasClass('VIP') ) return;

  $('.akedit').remove();

  $('article.node[id^=node-]').each(function(index) {

    //if ( !$(this).attr('id') || ( $(this).attr('id').slice(0,5) != 'node-') ) return;

    var nodeurl = $(this).attr('id').replace('-','/')+'/edit';
    $(this).append('<div class="akedit" style="position: absolute; bottom:0; left:0; z-index:9000; padding:.5em;"><a href="#overlay='+nodeurl+'"><i class="zmdi zmdi-edit"></i></a></div>');
  });
};



//HIDE MESSAGES ON CLICK
function hidemessages(){ console.log('hidemessages() ->');
  $('div#messagescontainer').on('click', function() {
    $(this).fadeOut(200)
  });
};
