console.log('catalogue.js ->');


var catalogue_events = function(){
	
	$(document).on('click', 'div.view-catalogue div.views-row div.line.first', function(event) { console.log('div.line.first <- click');
		if( $(event.target).is('a') ) return true;

		event.preventDefault();
		
		$(this).parent().find('div.box').slideToggle('fast');
		$(this).closest('div.views-row').toggleClass('o');
	});

	$(document).on('click', 'div.view-catalogue div.views-row img', function(event) { console.log('div.line.first <- click');
		event.preventDefault();
		$(this).closest('div.line').find('div.viewnode a').trigger('click');
	});


}; catalogue_events();

