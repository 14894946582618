//_akfunctions v2.1
console.log('_akfunctions.js');



//BILDSCHIRM BY AK
var refreshcount = 0;
var ratio, bb, bh;

$.fn.bildschirm = function(){

	bb = $(window).width();
	bh = $(window).height(); 
	
	ratio = (bb/bh > 1) ? 'landscape' : 'portrait'; 
	//shape = (bb/bh >= 0.75 && bb/bh <= 1.5) ? 'square' : 'panorama';

	is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	is_HD = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
	is_Retina = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
	is_iPhone = ((bb <= 320 && bh <= 568) || (bb <= 568 && bh <= 320)) && is_mobile;
	is_iPhone_35 = ((bb <= 320 && bh <= 450) || (bb <= 450 && bh <= 320)) && is_mobile;
	is_iPad = ((bb <= 768 && bh <= 1024) || (bb <= 1024 && bh <= 768)) && !is_iPhone && is_mobile;

	if ( ( bb >= 1350 && bh >= 900 && ratio=='landscape' ) || ( bb >= 675 && bh >= 900 && ratio=='portrait' ) ) size = 'XL';
	else if ( ( bb >= 960 && bh >= 640 && ratio=='landscape' ) || ( bb >= 480 && bh >= 640 && ratio=='portrait' ) ) size = 'L';
	else if ( ( bb >= 720 && bh >= 480 && ratio=='landscape' ) || ( bb >= 360 && bh >= 480 && ratio=='portrait' ) ) size = 'M';
	else size = 'S';

	//OUTPUT:

	this.removeClass('retina mobile desktop S M L XL landscape portrait square panorama iPad iPhone');
	
	if (is_Retina) this.addClass('retina');
	if (is_iPhone) this.addClass('iPhone');
	if (is_iPad) this.addClass('iPad');
	
	if (is_mobile) this.addClass('mobile');
	else this.addClass('desktop');
	
	//$els.addClass(ratio+' '+shape);
	this.addClass(ratio);
	this.addClass(size);
};
//BILDSCHIRM END



//AKCYCLE BY AK
$.fn.akcycleimg = function(elclass, usevisible) {

	var $akcycle = this;
	$akcycle.addClass('akcycle notyetclicked');

	var $akcycleimg = this.find(elclass);

	if ( ! $akcycleimg.next().length ) return false;
	
	$akcycleimg.addClass('akcycleimg');

	console.log( '.akcycleimg() on '+$akcycleimg.length +' imgs' );

	if (!usevisible){
		$akcycleimg.hide().first().show();
	}
	
	$akcycleimg.off();
	$akcycleimg.on('click', function(event) {

		$akcycle.removeClass('notyetclicked');

		event.preventDefault();
		$(this).next().length ? $(this).hide().next().show() : $(this).hide().siblings().first().show();
		
	});
};



//AKLIGHTBOX
var aklightbox = function(){

	var imagecontainerclass = '.item';

	$(document).on('click', '.aklightbox a', function(event) { console.log('.aklightbox a <- click');
		event.preventDefault();

		var index = $(this).closest(imagecontainerclass).index();

		var $source = $(this).closest('.aklightbox');

		$('body div#container').prepend('<div id="aklightbox"></div>');
		var $aklightbox = $('body div#container').find('div#aklightbox');

		$aklightbox.html( $source.html() );

		$aklightbox.find('a').each(function(index, el) {
			$(this).after('<img src="'+$(this).attr('href')+'">');
			$(this).remove();
		});

		$aklightbox.find(imagecontainerclass).hide().eq(index).show();

		$aklightbox.akcycleimg(imagecontainerclass, true);

	});

	$(document).on({
    click: function(event) {
    	event.preventDefault();
      if ( !$(event.target).is('img') ) $('#aklightbox').remove();
    },
    mouseover: function(event) {
    	event.preventDefault();
      $('#aklightbox').removeClass('close');
			if ( $(event.target).is(imagecontainerclass) ) $('#aklightbox').addClass('close');
    },
    mouseout: function(event) {
    	event.preventDefault();
      $('#aklightbox').removeClass('close');
    }
}, '#aklightbox '+imagecontainerclass);

}; //aklightbox();



//ADD PLACEHOLDER TO FORMS
$.fn.addplaceholder = function() { console.log('addplaceholder() called');

	this.find('input[type=text], input[type=password], textarea').each(function(index, el) {
		//$(this).prev('label').remove()
		$(this).attr('placeholder', $(this).prev('label').text() );
	});
};






//DEPRECEATED

$.fn.akmaxheight = function(initmaxheight){ console.log( '.akmaxheight() called' );

	initmaxheight = typeof initmaxheight !== 'undefined' ? initmaxheight : '0px';

	this.css('maxheight', 'none');
	var myheight = this.height();
	this.css('maxheight', initmaxheight);

	this.attr('data-akmaxheight', myheight);
	setTimeout(function(){
		$(this).css('maxheight', myheight+'px');
	}, 10);
};





