console.log('main.js ->');

var mySwiper

var initswiper = function(){ console.log('initswiper() -->');

  if ( !$('.swiper-container').length ){
    console.log('.swiper-container <-- non existent'); return false;
  }

  mySwiper = new Swiper ('.swiper-container', {
    direction: 'horizontal',
    grabCursor: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    loop: true

    // slidesPerView: 'auto',
    // centeredSlides: true,
    // loopAdditionalSlides: 3,
    // loopedSlides: 3,
    // loop: true
  });

  mySwiper.on('onClick', function(s,e){
    // console.log(e.target);
    if ( $(e.target).closest('.swiper-slide.video').length ){ return };
    if ( $(e.target).closest('.swiper-slide').find('img[alt]:not([alt=""])').length ){ return };
    
    mySwiper.slideNext();
  });

  mySwiper.on('onTransitionEnd', function(s,e){
    // console.log('onTransitionEnd');
    $('.swiper-container .ve-loaded').find('iframe').remove();
    $('.swiper-container .ve-loaded').removeClass('ve-loaded');
    // $(e.target).closest('.swiper-container').find('iframe').remove();

  });

  

}; initswiper();


var main_events = function(){

    $(document).on('click', '.projectheader', function(event) { console.log('.projectheader <- hide');
      event.preventDefault();
      $('div#container section#aklightbox').html('').hide();
    });


    var navissticky= true;
    $(document).scroll(function(event) {
        //var sp = $(window).scrollTop();
        var sp = window.pageYOffset

        if ( sp > 100){
          $('div#container header ul').fadeOut('fast'); console.log('ul.menu <- fadeOut');
          navissticky = false;
        }
        else if( sp < 100){
          $('div#container header ul').fadeIn('fast'); console.log('ul.menu <- fadeIn');
          navissticky = true;
        }
    });


    $(document).on({
        mouseover: function(){ console.log('div#container header <- mouseenter');
          if (navissticky) return;
          $('div#container header ul.menu').fadeIn('fast');
        },
        mouseleave: function(){ console.log('div#container header <- mouseleave');
          if (navissticky) return;
          $('div#container header ul.menu').fadeOut('fast');
        }
    },'div#container header');


    $(document).on('click', 'div#container header div.be a', function(event) {

      if ( !$('div#container header ul.menu').is(':visible') ){ console.log('div.be a <- PREVENTED');
        event.preventDefault();
        $('div#container header ul.menu').fadeIn('fast');
        navissticky = true;
      }

    });

}; main_events();



function add_video_to_images(e){ console.log('add_video_to_images()');

  $(document).on('click', '.projectslides img[alt]:not([alt=""])', function(e){

    e.preventDefault();
    e.stopPropagation();

    // if( !$(this).attr('alt') || $(this).attr('alt').trim() == '' ) return false;

    $(this).closest('.slide').addClass('ve-loaded');

    var alt = $(this).attr('alt').trim();
    if( isNaN(alt) ){
      $(this).closest('.swiper-slide').find('iframe').remove();
      $(this).closest('.swiper-slide').append('<iframe width="560" height="315" src="https://www.youtube.com/embed/'+ alt +'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"></iframe>');
    }
    else{
      $(this).closest('.swiper-slide').find('iframe').remove();
      $(this).closest('.swiper-slide').append('<iframe src="https://player.vimeo.com/video/'+ alt +'?autoplay=1" width="640" height="360" frameborder="0" allow="autoplay;"></iframe>');
    }

  });
  


} add_video_to_images();



function add_video_button_to_images(e){ 

  $('.projectslides img[alt]').each(function(i){ 
    if( $(this).attr('alt').trim() == '' ) return false;

    $(this).closest('.swiper-slide').find('ve-play').remove();
    $(this).closest('.swiper-slide').append('<div class="ve-play"></div>');

  });


} add_video_button_to_images();